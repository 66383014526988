import {AppBar, Box, Toolbar, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useContext, useEffect, useCallback, useRef, useLayoutEffect, useState} from "react";
import {NavLink, useLocation} from "react-router-dom";
import {MenuItem} from "../../model/Menu";
import TentacleHeader from '../../assets/images/tentacle_header.svg';
import Logo from "../../ui/icons/Logo";
import ContentContext from "../../store/content-context";

type Props = {
    item: MenuItem;
    index: number;
    location: any;
}

const MenuItemLink = ({item, index} : Props) => {
    const {palette} = useTheme();
    const location = useLocation();

    return (
        <NavLink end target={item.newPage ? '_blank' : '_self'} to={`${item.url}`} style={{textDecoration: "none"}} key={index}>
            {
                ({isActive}) => {
                    if(location.pathname === '/') {
                        return (
                            <Box sx={{display: "flex", alignItems: "center", margin: "auto 24px"}}>
                                <Typography sx={{
                                    textTransform: "uppercase",
                                    color: "#fff",
                                    backgroundColor: palette.secondary.main,
                                    padding: '6px 0 6px 10px',
                                    minWidth: '200px',
                                    border: 'none',
                                    fontSize: '24px',
                                    whiteSpace: "nowrap",
                                    fontWeight: isActive && location.hash === '#sprawdz-wiecej' ? 700 : 700
                                }}>
                                    {item.title}
                                </Typography>
                            </Box>)
                } else {
                        return (
                            <Box sx={{display: "flex", alignItems: "center", margin: "auto 24px"}}>
                                <Typography sx={{
                                    textTransform: "uppercase",
                                    color: "#fff",
                                    backgroundColor: palette.secondary.main,
                                    padding: '6px 0 6px 10px',
                                    minWidth: '200px',
                                    border: 'none',
                                    fontSize: '24px',
                                    whiteSpace: "nowrap",
                                    fontWeight: isActive ? 700 : 700
                                }}>
                                    {item.title}
                                </Typography>
                            </Box>)
                    }
                }
            }
        </NavLink>
    )
}

const FullHeader = () => {
    const {breakpoints} = useTheme();
    const {menu} = useContext(ContentContext);
    const location = useLocation();
    const [match, setMatch] = useState(true);
    const refBox = useRef(null);
    const lg = useMediaQuery(breakpoints.down('lg'));
    const isMenu = menu.menuItems && menu.menuItems.length > 0

    const handleScroll = useCallback(() => {
        if(!refBox.current) return
        if(refBox.current) {
            // @ts-ignore
            const {y} = refBox.current.getBoundingClientRect()
            if(!lg) {
                setMatch(true)
            }
        }
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, [handleScroll]);

    return (
        <Box ref={refBox} sx={{position: 'relative'}} component={"header"}>
            <Box component={"img"} src={TentacleHeader} sx={{position: 'absolute', width: '15%', top: 0, right: '2%', zIndex: 1005}} />
            <AppBar position="relative" sx={{position: 'fixed', top: 0, left: 0, height: '80px', marginTop: '64px', backgroundColor: isMenu ? "palette.primary.main" : 'transparent',  pointerEvents: isMenu ? 'auto' : 'none',  boxShadow: 'none', zIndex: 1002, filter: `${match && 'drop-shadow(0px 22px 48px rgba(0, 0, 0, 0.07)) drop-shadow(0px 4.91399px 10.7214px rgba(0, 0, 0, 0.0417275)) drop-shadow(0px 1.46302px 3.19205px rgba(0, 0, 0, 0.0282725))'}`}}>
                <Toolbar sx={{margin: "0 5%", display: 'flex'}}>
                    <Box sx={{marginRight: {xs: '0px', lg: '20px'}, pointerEvents: 'auto'}}>
                        <NavLink to={"/"}>
                            <Logo />
                        </NavLink>
                    </Box>
                    <Box sx={{display: "flex"}} component={"nav"}>
                        {menu.menuItems.map((item, index) => {
                            return <MenuItemLink key={index} index={index} item={item} location={location} />;
                        })}
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default FullHeader
