import { Grid, Typography, Box, useTheme } from "@mui/material";
import ArrowRight from "../ui/icons/ArrowRight";
import PinkTitle from "../ui/PinkTitle";
import TentacleWhereBuy from "../assets/images/tentacle_where_buy_section.svg";
import { useEffect, useState } from "react";

type Props = {
  content: any;
};

const WhereBuySection = ({ content }: Props) => {
  const { palette } = useTheme();

  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Box sx={{ position: "relative" }} component={"section"}>
      <Box
        component={"img"}
        src={TentacleWhereBuy}
        sx={{
          position: "absolute",
          top: "60%",
          right: 0,
          width: { xs: "20%", md: "auto" },
          zIndex: 101,
          transform: `translateY(-${offsetY * 0.3}px)`,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          bottom: { xs: "10%", sm: "50%", md: "90%", lg: "85%", xl: "5%" },
          left: { xs: "-150%", md: "-80%" },
          height: { xs: "985px", sm: "1100px", md: "985px" },
          width: { xs: "2000px", sm: "5000px" },
          zIndex: 11,
          backgroundColor: palette.primary.main,
          transform: "rotate(-30deg)",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          bottom: { xs: "-180%", sm: "-100%", md: "-70%", lg: "-200%", xl: "-230%" },
          left: "-50%",
          height: "985px",
          width: { xs: "2000px", sm: "5000px" },
          zIndex: 11,
          backgroundColor: palette.primary.main,
          transform: "rotate(-30deg)",
        }}
      />
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: { xs: "80px auto 0 auto", lg: "180px auto 0 auto" },
          zIndex: 103,
        }}>
        <PinkTitle title={content.title} sx={{ margin: "0 auto 72px auto" }} />
        <Grid
          container
          justifyContent={{ xs: "center", lg: "flex-end" }}
          alignItems={"center"}
          sx={(theme) => ({
            flexDirection: { xs: "column", lg: "row" },
            margin: "0 auto",
            [theme.breakpoints.up(1300)]: { width: "1172px" },
            [theme.breakpoints.down(1300)]: { width: "1000px" },
            [theme.breakpoints.down(1200)]: { width: "100%" },
          })}>
          <Grid
            item
            xs={10}
            sx={{
              display: "flex",
              gap: { xs: "0px", lg: "45px" },
              width: "100%",
              justifyContent: { xs: "center", lg: "flex-end" },
              alignItems: "center",
              flexDirection: { xs: "column-reverse", lg: "row" },
            }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: { xs: "center", lg: "flex-end" },
              }}
              mr={{ xs: 0, lg: 5.5 }}>
              <Box sx={{ width: { xs: "100%", lg: "100%" } }} mt={{ xs: 1, md: "20px" }}>
                <Typography
                  color={"#7E7979"}
                  sx={{
                    fontWeight: 700,
                    fontSize: "22px",
                    marginTop: { xs: "20px", lg: 0 },
                    textAlign: { xs: "center", lg: "right" },
                  }}>
                  {content.text}
                </Typography>
              </Box>
              <Box
                mt={3}
                sx={{
                  display: "flex",
                  gap: "40px",
                  justifyContent: { xs: "center", lg: "flex-end" },
                  alignItems: "center",
                  flexWrap: "wrap",
                }}>
                {content.logo.map((item: any, index: number) => {
                  return (
                    <Box component={"a"} key={index} href={item.link.url} target={"_blank"} rel={"nofollow"}>
                      <Box
                        component={"img"}
                        key={index}
                        src={item.img.url}
                        mt={{ xs: 1, lg: 0 }}
                        sx={{ width: "140px" }}
                      />
                    </Box>
                  );
                })}
              </Box>
              <Box
                mt={{ xs: 4, lg: 6 }}
                mb={"20px"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: palette.primary.light,
                  padding: "10px 30px",
                }}>
                <Box
                  component={"a"}
                  mr={2}
                  href={content.button.url}
                  sx={{
                    color: palette.secondary.main,
                    textDecoration: "none",
                    fontSize: "32px",
                    textTransform: "uppercase",
                    fontWeight: 700,
                  }}>
                  {content.button.title}
                </Box>
                <ArrowRight />
              </Box>
            </Box>
            <Box
              component={"img"}
              src={content.img}
              sx={{ width: { xs: "80%", sm: "70%", lg: "50%" }, height: "100%", objectFit: "cover" }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default WhereBuySection;
