import {Box, useTheme} from "@mui/material";

type Props = {
    degrees: number;
    onClick: () => void;
    sx?: any;
}

const ArrowButton = ({degrees, sx, onClick}: Props) => {
    const {palette} = useTheme();

    return (
        <Box
            sx={{
                ...sx,
                borderRadius: "50%",
                backgroundColor: palette.secondary.main,
                boxShadow: "0px 3px 4px #800000",
                maxWidth: "40px",
                maxHeight: "40px",
                zIndex: 200000,
                cursor: "pointer"
            }}
            onClick={onClick}
        >
            <Box
                sx={{
                    width:"40px",
                    height: "40px",
                    maxWidth: "40px",
                    maxHeight: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transform: `rotate(${degrees}deg)`,
                }}
            >
                <svg width="18" height="28" viewBox="0 0 18 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.94835 27.6401C8.69058 27.6401 8.50337 27.5374 8.2611 27.3547C8.07884 27.172 7.97650 26.9242 7.97650 26.6659L7.97650 3.68781L1.86117 9.81958C1.67867 10.0025 1.43115 10.1053 1.17306 10.1053C0.914968 10.1053 0.667506 10.0025 0.484947 9.81958C0.302508 9.63665 0.199922 9.38854 0.199922 9.12983C0.199922 8.87112 0.302508 8.62301 0.484947 8.50008L8.26024 0.64629C8.35052 0.555563 8.45777 0.483584 8.57585 0.435069C8.69393 0.385355 8.82051 0.360075 8.94835 0.360075C9.07619 0.360075 9.20277 0.385355 9.32085 0.435069C9.43893 0.483584 9.54618 0.555563 9.63646 0.64629L17.4118 8.50008C17.5943 8.62301 17.6968 8.87112 17.6968 9.12983C17.6968 9.38853 17.5943 9.63665 17.4118 9.81958C17.2293 10.0025 16.9817 10.1053 16.7236 10.1053C16.4655 10.1053 16.218 10.0025 16.0355 9.81958L9.92026 3.68781L9.92026 26.6659C9.92026 26.9242 9.81786 27.172 9.63559 27.3547C9.45333 27.5374 9.20612 27.6401 8.94835 27.6401Z" fill="white"/>
                </svg>
            </Box>
        </Box>
    )
}

export default ArrowButton;
