import ChoctopusSettings from "./ChoctopusSettings";

class Settings {
    settings: ChoctopusSettings;

    private constructor(setting: ChoctopusSettings) {
        this.settings = setting;
    }

    public static fromApiResponse(response: any) {
        return new Settings(
            ChoctopusSettings.fromApiResponse(response)
        )
    }
}

export default Settings;
