export class Brand {
  id: number;
  name: string;
  color: string;
  image: string;
  colorCard: string;

  constructor(id: number, name: string, color: string, image: string, colorCard: string) {
    this.id = id;
    this.name = name;
    this.color = color;
    this.image = image;
    this.colorCard = colorCard;
  }

  public static fromApiResponse(data: any) {
    return new Brand(
        data._embedded['wp:term'][0][0]['id'],
        data._embedded['wp:term'][0][0]['name'],
        data._embedded['wp:term'][0][0]['acf']['color'],
        data._embedded['wp:term'][0][0]['acf']['image']['url'],
        data._embedded['wp:term'][0][0]['acf']['color_card'],
    );
  }
}
