import {Typography, useTheme} from "@mui/material";

type Props = {
    title: string;
    sx?: any;
}

const PinkTitle = ({title, sx}: Props) => {
    const {palette} = useTheme();

    return (
        <Typography variant="h1" sx={{...sx, textAlign: 'center', backgroundColor: palette.secondary.main, color: 'white', padding: {xs: '5px 40px', sm: '5px 100px', lg: '5px 80px', xl: '5px 150px'}}}>{title}</Typography>
    )
}

export default PinkTitle;
