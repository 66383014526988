import {ReactNode} from "react";
import Header from "../components/header/Header";
import Footer from "../components/Footer";
import {Box} from "@mui/material";

const MainLayout = ({children}: {children: ReactNode}) => {

    return (
        <Box sx={{overflow: "hidden"}}>
            <Header />
            {children}
            <Footer />
        </Box>
    );
}

export default MainLayout;
