import { Grid, Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import ChoctopusXEkipa from "../ui/ChoctopusXEkipa";
import { useState } from "react";
import ArrowRight from "../ui/icons/ArrowRight";

type Props = {
  content: any;
};

const Slider = ({ content }: Props) => {
  const { palette } = useTheme();
  const matchesSmall = useMediaQuery("(min-height: 720px)");
  const matchesBig = useMediaQuery("(min-height: 760px)");
  const matchesLaptop = useMediaQuery("(min-height: 670px)");
  const [currentPage, setCurrentPage] = useState(0);

  return (
    <Grid component={"section"} position={"relative"}>
      <Box
        position={"absolute"}
        left={"50px"}
        top={"50%"}
        bottom={"50%"}
        zIndex={"1004"}
        sx={(theme) => ({
          cursor: "pointer",
          transform: "scaleX(-1)",
          "& *": {
            width: "56px",
            height: "40px",
            fill: "#5BC5F2",
          },
          [theme.breakpoints.between(0, 600)]: !matchesLaptop ? { top: "50%" } : { top: "75%" },
        })}
        onClick={() =>
          currentPage !== 0 ? setCurrentPage(currentPage - 1) : setCurrentPage(content.theme.length - 1)
        }>
        <ArrowRight />
      </Box>
      <Box
        position={"absolute"}
        right={"50px"}
        top={"50%"}
        bottom={"50%"}
        zIndex={"1004"}
        sx={(theme) => ({
          cursor: "pointer",
          "& *": {
            width: "56px",
            height: "40px",
            fill: "#5BC5F2",
          },
          [theme.breakpoints.between(0, 600)]: !matchesLaptop ? { top: "50%" } : { top: "75%" },
        })}
        onClick={() =>
          currentPage !== content.theme.length - 1 ? setCurrentPage(currentPage + 1) : setCurrentPage(0)
        }>
        <ArrowRight />
      </Box>
      <Box
        position={"absolute"}
        zIndex={"1"}
        display={"flex"}
        width={"100%"}
        justifyContent={"center"}
        bottom={"109px"}
        gap={"8px"}>
        {content.theme.map((theme: any, index: number) => {
          return (
            <Box
              key={index}
              bgcolor={currentPage === index ? "#5BC5F2" : "transparent"}
              border={"2px solid #5BC5F2"}
              width={"20px"}
              height={"20px"}
              borderRadius={"100%"}
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                setCurrentPage(index);
              }}
            />
          );
        })}
      </Box>
      <Box
        component={"img"}
        src={content.theme[currentPage].img}
        sx={{
          userSelect: "none",
          width: "100%",
          height: { xs: "640px", md: "930px", lg: "100vh" },
          objectFit: "cover",
          padding: { xs: 0, sm: "-50px auto 0 auto", md: "0 auto 0 auto" },
          filter: { xs: "brightness(50%)", md: "brightness(100%)" },
        }}
      />
      <ChoctopusXEkipa sx={{ mt: -10 }} />
      <Box
        sx={(theme) => ({
          position: "absolute",
          zIndex: 1001,
          transform: { xs: "translate(50%, -50%)", md: "translateX(0%)" },
          width: { xs: "80%", sm: "60%", md: "480px", lg: "580px", xl: "530px" },
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          [theme.breakpoints.up(1900)]: !matchesLaptop ? { top: "36%", right: "15%" } : { top: "50%", right: "15%" },
          [theme.breakpoints.between(1500, 1900)]: !matchesLaptop
            ? { top: "36%", right: "10%" }
            : { top: "42%", right: "10%" },
          [theme.breakpoints.between(1300, 1500)]: !matchesLaptop
            ? { top: "40%", right: "6%" }
            : { top: "46%", right: "6%" },
          [theme.breakpoints.between(1200, 1300)]: !matchesLaptop
            ? { top: "40%", right: "2.5%" }
            : { top: "46%", right: "2.5%" },
          [theme.breakpoints.down(1200)]: { top: "48%", right: "3%" },
          [theme.breakpoints.down(1000)]: { top: "48%", right: "1%" },
          [theme.breakpoints.down(900)]: { top: "43%", right: "49%" },
          [theme.breakpoints.down(500)]: matchesBig ? { top: "40%", right: "49%" } : { top: "54%", right: "49%" },
          [theme.breakpoints.down(400)]: matchesSmall ? { top: "44%", right: "49%" } : { top: "54%", right: "49%" },
        })}>
        <Box sx={{ backgroundColor: palette.secondary.main, padding: { sm: "5px 10px", md: "10px 20px" } }}>
          <Typography
            variant="h3"
            color="white"
            sx={{ textAlign: "center", fontSize: { xs: "35px", md: "55px", lg: "42px", xl: "50px" } }}>
            {content.theme[currentPage].title}
          </Typography>
        </Box>
        <Box mt={{ xs: "20px", xl: "20px" }} sx={{ width: { xs: "100%", xl: "95%" } }}>
          <Typography variant="body1" color="white" sx={{ fontWeight: 700, fontSize: { xs: "18px", md: "22px" } }}>
            {content.theme[currentPage].text}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default Slider;
