import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ContentContextProvider } from "./store/content-context";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider, responsiveFontSizes } from "@mui/material";
import { SnackbarContextProvider } from "./store/snackbar-context";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

let theme = createTheme({
  palette: {
    primary: {
      main: "#5BC5F2",
      light: "#E6DD00",
      dark: "#707070",
    },
    secondary: {
      main: "#E6007E",
      light: "#9ba037",
      dark: "#424242",
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: ["'Baloo Bhai 2'", "cursive", "sans-serif"].join(","),
    body1: {
      fontSize: "25px",
      fontWeight: 400,
    },
    body2: {
      fontSize: "30px",
      fontWeight: 400,
    },
    h1: {
      fontSize: "56px",
      fontWeight: 700,
    },
    h2: {
      fontSize: "41px",
      fontWeight: 700,
    },
    h3: {
      fontSize: "72px",
      fontWeight: 600,
    },
    h4: {
      fontSize: "54px",
      fontWeight: 700,
    },
    h5: {
      fontSize: "17px",
      fontWeight: 500,
    },
    h6: {
      fontSize: "26px",
      fontWeight: 700,
    }
  },
});

theme = responsiveFontSizes(theme);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <SnackbarContextProvider>
        <ContentContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ContentContextProvider>
      </SnackbarContextProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
