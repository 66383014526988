import React, { useContext, useEffect } from "react";
import "./App.css";
import { getAllPages, getAllProducts, getMenu, getSettings } from "./api/content-api";
import ContentContext from "./store/content-context";
import { Route, Routes, Navigate } from "react-router-dom";
import Page from "./model/Page";
import ScrollToTop from "./pages/ScrollToTop";
import Maintenance from "./pages/Maintenance";
import ProductPage from "./pages/ProductPage";
import Shop from "./components/Shop";
import { Product } from "./model/Product";
import { Brand } from "./model/Brand";
import {Helmet} from "react-helmet";

function App() {
  const { pages, setPages, setMenu, setProducts, setBrands, setSettings, products } = useContext(ContentContext);

  useEffect(() => {
    const fetchData = async () => {
      const pages = await getAllPages();
      const settings = await getSettings();
      const productsData = await getAllProducts();
      const products = productsData.map((p: any) => Product.fromApiResponse(p));
      const brands = filterBrands(productsData);
      const menu = await getMenu();

      setProducts(products);
      setBrands(brands);
      setPages([...pages]);
      setMenu(menu);
      setSettings(settings);
    };
    fetchData().catch((error) => console.log(error));
  }, []);

  const filterBrands = (productsData: any[]) => {
    const brands = productsData.map((data: any) => Brand.fromApiResponse(data));
    const result = [] as Brand[];
    brands &&
      brands.forEach((brand: any) => {
        const existingIndex = result.findIndex((item) => item.id === brand.id);
        if (existingIndex === -1) {
          result.push(brand);
        }
      });
    return result;
  };

  return (
    <Maintenance>
      <ScrollToTop>
        <Helmet>
          <title>Choctopus</title>
          {process.env.REACT_APP_ENV !== 'prod' && (
              <meta
                  name='robots'
                  content='noindex,nofollow'
              />
          )}
        </Helmet>
        <Shop />
        <Routes>
          {pages.map((page: Page) => page.toRoute())}
          {products.map((p) => (
            <Route key={p.slug} path={`/produkty/${p.slug}`} element={<ProductPage product={p} />} />
          ))}
          {products.map((p) => (
            <Route key={p.slug} path={`/products/${p.slug}`} element={<ProductPage product={p} />} />
          ))}
          <Route key={"default"} path={"*"} element={pages.length > 0 && <Navigate to={"/"} replace />} />
        </Routes>
      </ScrollToTop>
    </Maintenance>
  );
}

export default App;
