import Logo from "../../ui/icons/Logo";
import {
    AppBar,
    Box,
    ClickAwayListener,
    Drawer,
    IconButton,
    Toolbar,
    Typography,
    useTheme
} from "@mui/material";
import {useContext, useState} from "react";
import ContentContext from "../../store/content-context";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {MenuItem} from "../../model/Menu";
import MenuIcon from "../../ui/icons/MenuIcon";
import CloseIcon from "../../ui/icons/CloseIcon";


type Props = {
    menuItem: MenuItem;
    isActive: boolean;
    closeDrawer: () => void;
}

const MenuItemLink = ({menuItem, isActive, closeDrawer} : Props) => {
    const {palette} = useTheme();
    const [isExpanded, setIsExpanded] = useState(false);
    const location = useLocation();
    if(location.pathname === '/') {
    return (
        <ClickAwayListener onClickAway={() => setIsExpanded(false)}>
            <div>
                <Typography variant={"body1"} sx={{
                    textTransform: "none",
                    color: 'white',
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                    marginBottom: "48px",
                    fontWeight: isActive && location.hash === '#sprawdz-wiecej' ? 700 : 400,
                    backgroundColor: palette.secondary.main,
                    padding: '7px 12px 7px 10px',
                }}
                            onClick={() => setIsExpanded(!isExpanded)}
                >
                    {menuItem.title}
                </Typography></div>
        </ClickAwayListener>
    )
    } else {
        return (
            <ClickAwayListener onClickAway={() => setIsExpanded(false)}>
                <div>
                    <Typography variant={"body1"} sx={{
                        textTransform: "none",
                        color: 'white',
                        textAlign: "center",
                        whiteSpace: "nowrap",
                        cursor: "pointer",
                        marginBottom: "48px",
                        fontWeight: isActive ? 700 : 400,
                        backgroundColor: palette.secondary.main,
                        padding: '7px 12px 7px 10px',
                    }}
                                onClick={() => setIsExpanded(!isExpanded)}
                    >
                        {menuItem.title}
                    </Typography></div>
            </ClickAwayListener>
        )
    }
}

type MenuItemLinkWrapperProps = {
    menuItem: MenuItem;
    closeDrawer: () => void;
}

const MenuItemLinkWrapper = ({menuItem, closeDrawer}: MenuItemLinkWrapperProps) => {
    return <>{menuItem.subMenu && menuItem.subMenu.length > 0 ? <MenuItemLink menuItem={menuItem} isActive={false} closeDrawer={closeDrawer}/> :
        <NavLink end to={`${menuItem.url}`} target={menuItem.newPage ? '_blank' : '_self'} style={{textDecoration: "none", width: '60%'}} onClick={closeDrawer}>{({isActive}) => <MenuItemLink menuItem={menuItem} isActive={isActive} closeDrawer={closeDrawer}/>}</NavLink>}</>
}

const MinHeader = () => {
    const {palette} = useTheme();
    const {menu} = useContext(ContentContext);
    const [openDrawer, setOpenDrawer] = useState(false);
    const navigate = useNavigate();
    const isMenu = menu.menuItems && menu.menuItems.length > 0

    return <><AppBar position="fixed" sx={{height: {xs: "55px", md: "70px", marginTop: '64px', zIndex: 1002}, backgroundColor: isMenu ? "palette.primary.main" : 'transparent',  pointerEvents: isMenu ? 'auto' : 'none', boxShadow: 'none', maxWidth: "100%"}}
                     component={"header"}>
        <Toolbar variant="dense" sx={{display: 'flex', justifyContent: 'space-between', padding: {xs: "14px 10%"}, minHeight: "0px", maxWidth: "100%"}}>
            <Box onClick={() => navigate("/")} sx={{ pointerEvents: 'auto'}}>
                <Logo />
            </Box>
            {isMenu &&
            <IconButton sx={{marginLeft: "27px",  pointerEvents: 'auto'}} onClick={() => setOpenDrawer(true)}>
                <MenuIcon />
            </IconButton>}
        </Toolbar>
    </AppBar>
        <Drawer
            anchor={"right"}
            open={openDrawer}
            onClose={()=> setOpenDrawer(false)}
        >
            <Box sx={{height: '100%', display: "flex", flexDirection: "column", alignItems: "center", width: "375px", backgroundColor: palette.primary.main}}>
                <IconButton onClick={() => setOpenDrawer(false)} sx={{margin: "64px 0"}}>
                    <CloseIcon />
                </IconButton>
                {menu.menuItems.map((item, index) => {
                    return <MenuItemLinkWrapper key={index} menuItem={item} closeDrawer={() => setOpenDrawer(false)}/>
                })}
            </Box>
        </Drawer>
    </>
}

export default MinHeader;
