import Page from "../model/Page";
import { getRequest } from "./api";
import Settings from "../model/Settings";
import Menu from "../model/Menu";
import { Product } from "../model/Product";
import { Brand } from "../model/Brand";

export const getAllPages = async () => {
  const data = await getRequest("/wp/v2/pages?per_page=100", (error) => {});
  return Page.fromApiResponse(data);
};

export const getAllProducts = async () => {
  return await getRequest("/wp/v2/products?_embed", (error) => {});
};

export const getSettings = async () => {
  const data = await getRequest("/acf/v3/options/option", (error) => {});
  return Settings.fromApiResponse(data);
};

export const getMenu = async () => {
  const data = await getRequest("/ex/menu?location=topbar", (error) => {});
  return Menu.fromApiResponse(data);
};
