import PinkTitle from "../../ui/PinkTitle";
import {Grid, Box, useTheme, Typography, useMediaQuery} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import ContentContext from "../../store/content-context";
import YellowButton from "../../ui/YellowButton";
import TentacleProducts from '../../assets/images/tentacle_products_page.svg';

const ProductsGallery = () => {
    const {products} = useContext(ContentContext);
    const {palette, breakpoints} = useTheme();
    const md = useMediaQuery(breakpoints.down('md'));
    const lg = useMediaQuery(breakpoints.down('lg'));

    const [offsetY, setOffsetY] = useState(0);
    const handleScroll = () => setOffsetY(window.pageYOffset);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <Box sx={{position: 'relative', zIndex: 105}} component={"section"} mb={{xs: 0, md: 10, lg: 80}}>
            <Box component={"img"} src={TentacleProducts} sx={{position: 'absolute', display: {xs: 'none', md: 'block'}, top: {xs: '50%', lg: '110%'}, left: 0, zIndex: 100, transform: `translateY(-${offsetY * 0.5}px)`}} />
            <Grid container justifyContent={"center"} flexDirection={'column'} alignItems={"center"} sx={theme => ({position: 'relative',
                [theme.breakpoints.up(1300)]: {width: '1172px'},
                [theme.breakpoints.down(1300)]: {width: '1000px'},
                [theme.breakpoints.down(1200)]: {width: '100%'},
                margin: {xs: '160px auto 0 auto', lg: '160px auto 160px auto', xl: '160px auto 160px auto'}})}>
                <PinkTitle title={"PRODUKTY"} sx={{margin: '36px auto', zIndex: 100}}/>
                <Box
                    sx={{
                        position: "absolute",
                        top: {xs:"10%", sm:"20%",  lg:"30%"},
                        left: '-10',
                        height: "885px",
                        width: {xs:'2000px', sm:'5000px'},
                        zIndex: 10,
                        backgroundColor: palette.primary.main,
                        transform: "rotate(-30deg)"
                    }}
                />
                {products &&
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: {xs: 'center', lg: 'flex-end'}, gap: {xs: '80px', lg: '150px'}, flexWrap: 'wrap', flexDirection: {xs: 'column-reverse', lg: 'row-reverse'}, margin: {xs: '30px auto 50px auto', lg: '30px auto 0 auto'}, zIndex: 101}}>
                        {products.map((item: any, index: number) => {
                            return (
                                <Box key={index} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                    <Box component={"img"} src={item.tileImage} sx={{width: {xs: '100%', lg: '380px'}}} />
                                    <Typography variant="body2" color={(item.textColor !== "white" && lg) || (index !== products.length - 1 && lg) ? palette.primary.dark : 'white' } mt={0} sx={{fontWeight: 700, textAlign: 'center'}}>{item.productName}</Typography>
                                    <YellowButton title={"SZCZEGÓŁY"} url={`/produkty/${item.slug}`} sx={{width: '230px', textAlign: 'center', marginTop: '20px'}} />
                                </Box>
                            )
                        })}
                    </Box>
                }
            </Grid>
        </Box>
    )
}

export default ProductsGallery
