import { useContext, useEffect, useState } from "react";
import ContentContext from "../../store/content-context";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import PinkTitle from "../../ui/PinkTitle";
import { Product } from "../../model/Product";
import YellowButton from "../../ui/YellowButton";
import TentacleProducts from "../../assets/images/tentacle_products.svg";
import TentacleProductsSecond from "../../assets/images/tentacle_products2.svg";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";

const ProductImage = styled("img")({});

type ProductRowProps = {
  product: Product;
  last: boolean;
  index: number;
};

const ProductsMainPage = () => {
  const { brands } = useContext(ContentContext);
  const { palette, breakpoints } = useTheme();
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Grid item container component={"section"} sx={{ position: "relative" }}>
      <Grid
        item
        container
        flexDirection={"column"}
        alignItems={"center"}
        mt={10}
        sx={(theme) => ({
          zIndex: 102,
          [theme.breakpoints.up(1300)]: { width: "1172px" },
          [theme.breakpoints.down(1300)]: { width: "1000px" },
          [theme.breakpoints.down(1200)]: { width: "100%" },
          margin: "0 auto",
        })}>
        <Box
          component={"img"}
          src={TentacleProducts}
          sx={{
            position: "absolute",
            width: { xs: "40%", md: "22%" },
            top: "10%",
            left: 0,
            zIndex: 101,
            transform: `translateY(-${offsetY * 0.5}px)`,
          }}
        />
        <Box
          component={"img"}
          src={TentacleProductsSecond}
          sx={{
            position: "absolute",
            width: { xs: "40%", md: "26%" },
            top: "120%",
            left: 0,
            zIndex: 101,
            transform: `translateY(-${offsetY * 0.4}px)`,
          }}
        />
        <Grid
          item
          container
          flexDirection={"column"}
          alignItems={"center"}
          sx={{
            zIndex: 102,
          }}>
          <PinkTitle title={"NASZE WSPÓŁPRACE"} sx={{ whiteSpace: "normal", mt: "80px", mb: { xs: 0, lg: "92px" } }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: { xs: "center", lg: "flex-end" },
              gap: { xs: "40px", lg: "80px" },
              flexWrap: "wrap",
              flexDirection: { xs: "column", lg: "row" },
              pb: "120px",
              margin: { xs: "30px auto 50px auto", lg: "30px auto 0 auto" },
              zIndex: 101,
            }}>
            {brands &&
              brands.map((brand: any, index: number) => (
                <Link key={index} to={"/produkty/"} state={{ index: index }}>
                  <Box
                    bgcolor={brand.colorCard ? brand.colorCard : brand.color}
                    width={"531px"}
                    height={"212px"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{
                      cursor: "pointer",
                      boxShadow: "-30px 45px 4px 0px #00000040",
                      "&:hover": {
                        filter: "saturate(125%)",
                        boxShadow: "-30px 45px 4px 0px #00000055",
                      },
                      [breakpoints.down("sm")]: { width: "360px", height: "140px" },
                    }}
                    onClick={() => {}}>
                    <Box
                      component={"img"}
                      src={brand.image}
                      height={"150px"}
                      sx={{
                        [breakpoints.down("sm")]: { height: "100px" },
                      }}
                    />
                  </Box>
                </Link>
              ))}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductsMainPage;
