import {WPImage} from "./WPImage";

export class Logo {
    image: any;
    link: any;

    constructor(image: any, link: any) {
        this.image = image;
        this.link = link;
    }

    public static fromApiResponse(acf: any) {
        return new Logo(
            acf.image,
            acf.link,
        )
    }

}
