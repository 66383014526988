import {Grid, Box, Typography, useTheme, useMediaQuery} from "@mui/material";
import CircleArrowLeft from "../ui/icons/CircleArrowLeft";
import CircleArrowRight from "../ui/icons/CircleArrowRight";
import PinkTitle from "../ui/PinkTitle";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import DOMPurify from "dompurify";

type Props = {
    content: any;
}

const SocialMediaSection = ({content}: Props) => {
    const {palette, breakpoints} = useTheme();
    const [index, setIndex] = useState(0)
    const location = useLocation();

    useEffect(() => {
        if(location.hash === '#sprawdz-wiecej') {
            const element = document.getElementById('check-more-section');
            if(element) {element.scrollIntoView({behavior: 'smooth'})}
        }
    }, [location])

    const lg = useMediaQuery(breakpoints.up('lg'));

    const socialMediaHandlerNext = () => {
        setIndex(content.img_sm.length - 1 === index ? 0 : index + 1)
    }

    const socialMediaHandlerPrev = () => {
        setIndex(index === 0 ? content.img_sm.length - 1 : index - 1)
    }

    return (
        <Grid sx={{position: 'relative'}} component={"section"}>
            {/* {content &&
                <Box
                    sx={{
                        position: "absolute",
                        top: "-200px",
                        left: {xs: '-53%', lg: "-30%"},
                        height: {xs: '980px', lg: "860px"},
                        width: {xs: '215%', lg: '170%'},
                        zIndex: 10,
                        backgroundColor: palette.primary.main,
                        transform: {xs: "rotate(-20deg)", md: "rotate(-29deg)"}
                    }}
                />
            } */}
            <Grid item xs={10} lg={12} container justifyContent={{xs: 'center', lg: `${content.img_sm[0].img_post ? 'space-between' : 'center'}`}} flexDirection={{xs: 'column', lg: 'row'}} alignItems={{xs: 'center', lg: "flex-start"}} sx={theme => ({position: 'relative',
                [theme.breakpoints.up(1300)]: {width: '1172px'},
                [theme.breakpoints.down(1300)]: {width: '1000px'},
                [theme.breakpoints.down(1200)]: {width: '100%'}, margin: {xs: '80px auto 0 auto', lg: '190px auto 0 auto'}, zIndex: content.img_section ? 104 : 107})}>
                <Box sx={{display: 'flex', flexDirection: {xs: 'column', lg: 'row'}, justifyContent: 'space-between', alignItems: {xs: 'center', lg: 'flex-start'}, margin: '0'}}>
                    {content.img_sm[0].img_post && <Grid item sx={{position: 'relative'}} ml={{xs: 'auto', lg: 0}} mr={{xs: 'auto', lg: 4}} xs={12} lg={12} >
                        <Box component={"a"} href={content.img_sm[index].link_post} target={"_blank"}>
                            <Box component={"img"} src={content.img_sm[index].img_post} sx={{'&:hover': {cursor: 'pointer'}, width: {xs: '100%', lg: '100%'}, height: '100%'}} />
                        </Box>
                        <Box component={"img"} src={content.img_sm[index].img_product} sx={theme => ({position: 'absolute', [theme.breakpoints.down(600)]: {bottom: '-22%'}, [theme.breakpoints.down(450)]: {bottom: '-36%'}, [theme.breakpoints.up(600)]: {bottom: '-20%'}, left: {xs: '6%', sm: '14%', lg: '12%'}, objectFit: 'cover', width: {xs: '180px', sm: '260px', lg: '250px'}})} />
                    </Grid>}
                    <Grid item sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}} id={"check-more-section"} xs={12} lg={12}>
                        <PinkTitle title={content.title} sx={{mb: 9, mt: {xs: 10, lg: 0}, width: {xs: 'auto', lg: `${content.img_sm[0].img_post ? 'auto' : '480px'}`}, margin: {xs: '20px auto 0 auto', lg: `${content.img_sm[0].img_post ? 0 : '0 auto'}`}}} />
                        <Box sx={{margin: {xs: `${content.img_sm[0].img_post ? '100px auto 0 auto' : '0 auto 0 auto'}`, lg: `${content.img_sm[0].img_post ? '0 0 0 20px' : '0 auto'}`}}}>
                            <Typography color={lg ? '#7E7979' : '#7E7979'} sx={{fontWeight: 700, fontSize: '22px', margin: {xs: '60px auto 0 auto', lg: '76px auto 0 auto'}}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(content.img_sm[index].text)}}></Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignSelf: 'center', gap: '25px'}} mt={{xs: 6, lg: 6}} mr={{xs: 0, lg: 8}}>
                            {content.img_sm.length > 1 && <>
                                <Box sx={{'&:hover': {cursor: 'pointer'}}} onClick={socialMediaHandlerPrev}>
                                    <CircleArrowLeft />
                                </Box>
                                <Box sx={{'&:hover': {cursor: 'pointer'}}} onClick={socialMediaHandlerNext}>
                                    <CircleArrowRight />
                                </Box>
                            </>}
                        </Box>
                    </Grid>
                </Box>
                {content.img_section &&
                    <Box sx={{margin: {xs: '40px auto 0 auto', md: '40px auto 100px auto', lg: '40px auto 0 auto'}}}>
                        <Box component={"img"} src={content.img_section} sx={{position: 'relative', zIndex: 98, width: {xs: '100%', sm: '100%', md: '100%', lg: '723px'}, height: {xs: '100%', lg: '435px'}, objectFit: 'cover'}} />
                    </Box>
                }
            </Grid>
        </Grid>
    )
}

export default SocialMediaSection
