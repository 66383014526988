import {Logo} from "./Logo";
import {LogoWithText} from "./LogoWithText";

class ShopLink {
    url: string;
    iconUrl: string;
    iconHeight: number;

    constructor(url: string, iconUrl: string, iconHeight: string) {
        this.url = url;
        this.iconUrl = iconUrl;
        this.iconHeight = Number(iconHeight);
    }
}

class ShopSettings {
    color: string;
    iconUrl: string;
    iconWidth: number;
    links: ShopLink[];

    constructor(color: string, iconUrl: string, iconWidth: string, links: ShopLink[]) {
        this.color = color;
        this.iconUrl = iconUrl;
        this.iconWidth = Number(iconWidth);
        this.links = links;
    }
}

class ChoctopusSettings {
    facebook: string;
    instagram: string;
    tiktok: string;
    shopSettings: ShopSettings;
    titleOfLogos: string;
    logos: Logo[];
    logoWithText: LogoWithText;

    private constructor(facebook: string, instagram: string, tiktok: string, shopSettings: ShopSettings, titleOfLogos: string, logos: Logo[], logoWithText: LogoWithText) {
        this.facebook = facebook;
        this.instagram = instagram;
        this.tiktok = tiktok;
        this.shopSettings = shopSettings;
        this.titleOfLogos = titleOfLogos
        this.logos = logos
        this.logoWithText = logoWithText
    }

    public static fromApiResponse({acf}: any) {
        return new ChoctopusSettings(
            acf.facebook,
            acf.instagram,
            acf.tiktok,
            new ShopSettings(
                acf.background_color,
                acf.shop_icon,
                acf.header_width,
                acf.links.map((item: any) => new ShopLink(item.link.url, item.link.icon, item.link.icon_height))
            ),
            acf.title_of_logos,
            acf.loga.logos.map((logo: any) => Logo.fromApiResponse(logo)),
            acf.logoWithText
        )
    }

}

export default ChoctopusSettings;
