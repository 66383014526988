import { Box, Typography, useTheme } from "@mui/material";
import { keyframes } from "@emotion/react";

type Props = {
  sx?: any;
};

const text =
  "CHOCTOPUS X EKIPA • CHOCTOPUS X SPIDER-MAN • CHOCTOPUS X MR. POLSKA • CHOCTOPUS X EKIPA • CHOCTOPUS X SPIDER-MAN • CHOCTOPUS X MR. POLSKA • CHOCTOPUS X EKIPA • CHOCTOPUS X SPIDER-MAN • CHOCTOPUS X MR. POLSKA • CHOCTOPUS X EKIPA • CHOCTOPUS X SPIDER-MAN • CHOCTOPUS X MR. POLSKA • CHOCTOPUS X EKIPA • CHOCTOPUS X SPIDER-MAN • CHOCTOPUS X MR. POLSKA • ";

const ChoctopusXEkipa = ({ sx }: Props) => {
  const { palette } = useTheme();

  const slide = keyframes`
          0% {
            transform: translateX(-50%);
          }
          100% {
            transform: translateX(0);
          }
          0% {
              transform: translateX(-50%);
          }
        `;

  return (
    <Box
      sx={{
        backgroundColor: palette.primary.light,
        height: "70px",
        display: "flex",
        alignItems: "center",
        position: "relative",
        ...sx,
      }}>
      <Typography
        sx={{
          fontSize: "40px !important",
          color: palette.secondary.main,
          fontWeight: 700,
          whiteSpace: "nowrap",
          animation: `${slide} 15s linear infinite`,
        }}>
        {text}
      </Typography>
    </Box>
  );
};

export default ChoctopusXEkipa;
