import {Box, useTheme} from "@mui/material";
import ArrowRight from "./icons/ArrowRight";

type Props = {
    title: string;
    url: string;
    sx?: any;
}

const YellowButton = ({title, url, sx}: Props) => {
    const {palette} = useTheme();

    return <Box component={"a"} href={url} sx={{
        ...sx,
        display: 'flex',
        textDecoration: 'none',
        alignItems: 'center',
        backgroundColor: palette.primary.light,
        padding: '10px 30px'
    }}>
        <Box mr={2} sx={{
            color: palette.secondary.main,
            fontSize: '32px',
            textTransform: 'uppercase',
            fontWeight: 700
        }}>
            {title}
        </Box>
        <ArrowRight/>
    </Box>
}

export default YellowButton;
