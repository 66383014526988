import PinkTitle from "../ui/PinkTitle";
import {Grid, Box} from "@mui/material";
import TentacleWhereBuy from '../assets/images/tentacle_where_buy_page.svg';
import {useEffect, useState} from "react";

type Props = {
    content: any;
}

const GalleryLogos = ({content}: Props) => {
    const [offsetY, setOffsetY] = useState(0);
    const handleScroll = () => setOffsetY(window.pageYOffset);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <Box sx={{position: 'relative', zIndex: 107}} mb={{xs: 10, sm: 0, lg: 30}} component={"section"}>
            <Box component={"img"} src={TentacleWhereBuy} sx={{position: 'absolute', display: {xs: 'none', md: 'block'}, width: {xs: '60%', sm: '30%'}, top: {xs: '60%', sm: '40%'}, left: 0, zIndex: 103, transform: `translateY(-${offsetY * 0.3}px)`}} />
            <Grid container justifyContent={"center"} flexDirection={"column"} alignItems={"center"} sx={theme => ({position: 'relative',
                [theme.breakpoints.up(1300)]: {width: '1172px'},
                [theme.breakpoints.down(1300)]: {width: '1000px'},
                [theme.breakpoints.down(1200)]: {width: '100%'},
                margin: {xs: '160px auto 0 auto', lg: '160px auto 0 auto', xl: '160px auto 0 auto'}, zIndex: 103})}>
                {content.title && <PinkTitle title={content.title} sx={{margin: '36px auto'}}/>}
                {content.logos.length > 0 &&
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '150px', flexWrap: 'wrap', flexDirection: {xs: 'column', lg: 'row'}, margin: {xs: '10px auto 0 auto', lg: '10px auto 0 auto'}}}>
                        {content.logos.map((item: any, index: number) => {
                            return (
                                <Box component={"a"} href={item.link.url} target={"_blank"} rel={"nofollow"}>
                                    <Box component={"img"} src={item.img.url} key={index} sx={{width: {xs: '100%', lg: 'auto'}}} />
                                </Box>
                            )
                        })}
                    </Box>
                }
            </Grid>
        </Box>
    )
}

export default GalleryLogos
