import {Grid, Box} from "@mui/material";
import FooterImage from '../assets/images/footer_bg.svg';
import OctopusImage from '../assets/images/octopus.svg';
import TentacleImage from '../assets/images/tentacle_footer.svg';
import Logo from '../assets/images/logo-big.svg';
import FacebookIcon from "../ui/icons/FacebookIcon";
import InstagramIcon from "../ui/icons/InstagramIcon";
import TikTokIcon from "../ui/icons/TikTokIcon";
import {useContext} from "react";
import ContentContext from "../store/content-context";
import DOMPurify from "dompurify";

const Footer = () => {
    const {setting} = useContext(ContentContext)

    return (
        <Grid container component={"footer"} mb={-1.5} mt={{xs: 0, sm: 0, lg: 25}}>
            <Box sx={{width: '100%'}}>
                <Box sx={{position: 'relative', zIndex: 106, maxWidth: '1372px', display: 'flex', justifyContent: {xs: 'center', xl: 'space-between'}, flexWrap: 'wrap', flexDirection: {xs: 'column', md: 'row'}, alignItems: 'center', gap: '20px', margin: {xs: '60px auto 20px auto', sm: '100px 64px 20px 64px', lg: '14px 64px 0 64px', xl: '14px auto 0 auto'}}}>
                    <Box component={"img"} src={Logo} />
                    <Box component={"a"} href={setting.facebook} target={"_blank"}>
                        <FacebookIcon />
                    </Box>
                    <Box component={"a"} href={setting.instagram} target={"_blank"}>
                        <InstagramIcon />
                    </Box>
                    <Box component={"a"} href={setting.tiktok} target={"_blank"}>
                        <TikTokIcon />
                    </Box>
                    {setting.logos ? <Box mr={{xs: '40px', lg: 0}} ml={{xs: '40px', lg: 4}}  sx={{display: 'flex', justifyContent: "center", flexBasis: '35%', marginTop: {xs: '40px', lg:'130px'}, flexDirection: 'column', alignItems: {xs: 'center', lg: 'flex-start'}, gap: {xs: '10px', sm: '20px'}}}>
                        <Box component={"h5"} sx={{color: 'white', fontSize: '28px', margin: 0, alignSelf: 'flex-start'}}>{setting.titleOfLogos}</Box>
                        <Box sx={{display: 'flex', flexWrap: {xs: 'wrap', sm: 'nowrap'}, justifyContent: "center", alignItems: 'center', gap: {xs: '20px', sm: '30px'}}}>
                            {setting.logos.map((logo, index) => {
                                return (
                                    <Box component={"a"} key={index} href={logo.link.url} target={"_blank"}>
                                        <Box component={"img"} src={logo.image.url} sx={{width: {xs: '80px', sm: '120px'}}} />
                                    </Box>
                                )
                            })}
                        </Box>
                        {setting.logoWithText ? <Box sx={{display: 'flex', justifyContent: "center", alignItems: 'center', gap: {xs: '20px', md: '20px'}}}>
                            <Box component={"a"} href={setting.logoWithText.link.url} target={"_blank"}>
                                <Box component={"img"} src={setting.logoWithText.logo.url} sx={{width: {xs: '80px', sm: '100px'}}} />
                            </Box>
                            <Box sx={{color: 'white', textDecoration: 'none'}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(setting.logoWithText.text)}}></Box>
                        </Box> : ''}
                    </Box> : ''}
                </Box>
                <Box sx={{position: 'relative', zIndex: 103}} mt={{xs: 0, sm: 0}}>
                    <Box component={"img"} src={FooterImage} sx={{width: '100%', height: {xs: '900px', md: '100%'}, objectFit: 'cover', zIndex: 100}} mt={-110} />
                    <Box component={"img"} src={OctopusImage} sx={{position: 'absolute', bottom: 0, left: 0, zIndex: 10, width: {xs: '300px', sm: '450px', md: '650px', lg: '800px', xl: '939px'}}} />
                    <Box component={"img"} src={TentacleImage} sx={{position: 'absolute', bottom: 0, right: 0, zIndex: 99, width: {xs: '110px', sm: '190px', md: '250px', lg: '32%', xl: '32%'}}} />
                </Box>
            </Box>
        </Grid>
    )
}

export default Footer
